'use client';
import { Logo } from '@/components/ui/logo';
import { useSystemHealth } from '@/hooks/use-system-health';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie } from 'cookies-next';
import { useEffect } from 'react';
import { hexToHsl, useGetBranding } from './hooks/use-get-branding';
import Maintenance from './login/maintenance';

export default function LoginLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = useQueryClient();
  // clear global filter cookies
  useEffect(() => {
    deleteCookie('globalFilter');
    // reset entire query cache
    queryClient.clear();
  }, [queryClient]);

  const { data } = useGetBranding();
  useEffect(() => {
    const doc = document.querySelector(':root') as HTMLElement;
    if (data?.branding?.[0]?.colors?.primary) {
      doc?.style.setProperty(
        '--primary',
        `${hexToHsl(data?.branding?.[0]?.colors?.primary)}`
      );
    }
  }, [data]);

  const { data: systemHealth } = useSystemHealth();

  return (
    <main className='relative'>
      <div className='container grid h-screen flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0'>
        <div className='relative hidden h-full flex-col overflow-hidden bg-muted p-10 text-white dark:border-r lg:flex'>
          <div
            className='absolute inset-0 bg-cover'
            style={{
              backgroundImage: `url(${data?.branding?.[0]?.images?.login_bg?.signed_url_get ?? '/assets/login.webp'})`,
            }}
          />
          <div className='relative z-20 flex items-center text-lg font-medium'>
            <Logo />
          </div>
        </div>
        {systemHealth?.maintenance?.active ? <Maintenance /> : children}
      </div>
    </main>
  );
}
