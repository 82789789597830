'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useSystemHealth } from '@/hooks/use-system-health';
import { toReadableDateTime } from '@/lib/dates';

export default function Maintenance() {
  const { data: systemHealth } = useSystemHealth();

  return (
    <div className='flex h-full items-center justify-center'>
      <Card className='w-full max-w-md'>
        <CardHeader className='border-b-0'>
          <CardTitle className='flex items-center gap-2'>
            <div className='size-3 animate-pulse rounded-full bg-red-500' />
            Systemunderhåll pågår
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            Vi utför för närvarande underhåll på systemet för att förbättra din
            upplevelse.
            {systemHealth?.maintenance?.eta && (
              <p className='mt-2'>
                Underhåll beräknades påbörjas tidigast:{' '}
                {toReadableDateTime(systemHealth.maintenance.eta)}
              </p>
            )}
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
